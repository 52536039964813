<template>
	<div class="myForm">
		<el-input id="myInput"></el-input>
		<!-- pc端 -->
		<div class="pccontent">
			<div class="pcTop">
				<div>{{nowData.extra?'[附加题]':''}}{{nowData.typeStr}}题（{{nowData.unitScore}}）分</div>
				<div><strong>{{nowIndex+1}}</strong>&nbsp;/&nbsp;{{examData.length}}</div>
				<div v-if="!tipShow">
					<p>剩余时间:{{minutesLimit}}</p>
				</div>
				<div>
					<el-button size="small" type="primary" v-if="nowIndex" @click="preClick">上一题</el-button>
				</div>
				<div>
					<el-button size="small" type="primary" v-if="nowIndex<examData.length-1" @click="nextClick">下一题
					</el-button>
					<el-button size="small" type="primary" v-if="(nowIndex==examData.length-1)&&(!tipShow)"
						@click="submitClick">完成
					</el-button>
				</div>
			</div>
			<hr>
			<div class="pcMain">
				<div v-if="nowData.type===3">
					<!-- <el-input id="myhiddenInput" v-model="inputValue" class="hiddenInput" maxlength="2"></el-input> -->
					<pre class="preContent"></pre>
				</div>
				<div v-if="(nowData.type===1) || (nowData.type===2)">
					<div class="titleCont">{{nowData.content}}</div>
				</div>
				<div class="selectOptions">
					<!-- 单选 -->
					<ol type="A" v-if="nowData.type===1">
						<el-radio-group v-model="answerRadioValue" :disabled="tipShow" @change="radioChange">
							<el-radio v-for="(item,index) in list" :label="item.value">
								<li>{{item.label}}</li>
							</el-radio>
						</el-radio-group>
					</ol>
					<!-- 多选 -->
					<ol type="A" v-if="nowData.type===2">
						<el-checkbox-group v-model="answerCheckValue" :disabled="tipShow">
							<el-checkbox v-for="(item,index) in list" :label="item.value">
								<li>{{item.label}}</li>
							</el-checkbox>
						</el-checkbox-group>
					</ol>
				</div>
				<!-- 信息 -->
				<div class="correct" v-if="tipShow"><span>正确答案：{{correct}}</span></div>
				<div class="myInfor">
					<!-- 出题人信息 -->
					<div class="myAuthor">
						出题人：{{nowData.author}}
					</div>
					<!-- 提示信息 答题时不能看-->
					<div class="myTips" v-if="tipShow">
						<el-popover placement="bottom" title="提示" width="200" trigger="click" :content="nowData.tips">
							<p type="text" slot="reference"><i class="el-icon-s-opportunity"></i>查看提示</p>
						</el-popover>
					</div>
				</div>

			</div>
		</div>
		<!-- 提交成功弹框出现，显示分数，查看解析，返回 -->
		<el-dialog class="innerDialog" title="提示" :visible.sync="dialogVisible" width="500px" append-to-body>
			<div class="myscore">
				<div>
					<p>本次作答分数</p>
					<p>正题&nbsp;<strong>{{score}}</strong>&nbsp;分</p>
					<p>附加题&nbsp;<strong>{{scoreExtra}}</strong>&nbsp;分</p>
				</div>
				<div>
					<img src="../../assets/book.png" />
				</div>
			</div>
			<div class="myDeriction">
				<el-button size="small" type="primary" class="searchButton" @click="goback">返回</el-button>
				<el-button size="small" type="warning" @click="seeDetail">查看解析</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import $ from 'jquery';
	import Cookies from 'js-cookie';

	export default {
		name: 'Form',
		data() {
			return {
				minutesLimit: '',
				// 未答还是已答
				tipShow: false,
				anwerValue: '', //答案
				// 单选答案
				answerRadioValue: '',
				// 多选答案
				answerCheckValue: [],
				// 试题data
				examData: [],
				// 答案提交时使用
				answers: [],
				// 当前题的数组
				nowData: [],
				nowIndex: 0,
				// 答案数组对象
				answersArr: [],
				// 当前id
				categoryId: "",
				userId: "",
				// 填空题
				inputValue: "", //用于隐藏起来的输入框
				// 填空题值的数组
				inputArr: [],
				// 提交成功之后对话框
				dialogVisible: false,
				score: '',
				scoreExtra:'',
				second: '', //用于倒计时
				// 提交成功答题详情
				detail: [],
				correct: '', //正确答案
				timeOut: null,
				inputLength:0,//当前填空题有几个（）
				inputLengthArr:[],
				list:[],//单选多选循环渲染list
			}
		},
		created() {
			var that=this;
			document.onkeydown = function(e) {
				if(e.key=='ArrowLeft' || e.keyCode==37 || e.key=='ArrowUp' || e.keyCode==38){
					that.preClick();
				}
				else if(e.key==' ArrowRight' || e.keyCode==39 || e.key=='ArrowDown' || e.keyCode==40){
					that.nextClick();
				}
			}
		},
		mounted() {
			var that = this;
			setTimeout(function() {
				$("body").on('keyup', ".myhiddenInput", function(e) {
					that.inputValue = $(this).val().replace(/\s+/g, "");
					// 获取第几个()
					var targetIndex = $(this).parent().data("index") - 1;
					that.show(targetIndex);
					// 赋值
					for (var i = 0; i < that.inputArr.length; i++) {
						// 若本来有值则删除
						if ((that.inputArr[i].index === that.nowIndex) && (that.inputArr[i].targetIndex ===
								targetIndex)) {
							that.inputArr.splice(i, 1);
						}
					}
					if(that.inputValue){
						var obj = {
							index: that.nowIndex,
							targetIndex: targetIndex,
							value: that.inputValue
						};
						that.inputArr.push(obj);
					}
					// 所有内容均赋值
					// var hiddenLength = $('.myhiddenInput').length;
					// for (var j = 0; j < hiddenLength; j++) {
					// 	if (that.inputArr.length > 0) {
					// 		for (var k = 0; k < that.inputArr.length; k++) {
					// 			// 找到对应的值修改
					// 			if (that.inputArr[k].targetIndex === targetIndex) {
					// 				that.inputArr[k].value = that.inputValue;
					// 			}
					// 			// 找到存在数组，保持不变
					// 			else if (that.inputArr[k].targetIndex === j) {
					// 				console.log('no')
					// 			}
					// 			// 没有对应的值则添加
					// 			else {
					// 				var obj = {
					// 					index: that.nowIndex,
					// 					targetIndex: targetIndex,
					// 					value: ''
					// 				};
					// 				that.inputArr.push(obj);
					// 			}
					// 		}
					// 	} else {
					// 		var obj = {
					// 			index: that.nowIndex,
					// 			targetIndex: j,
					// 			value: j == targetIndex ? that.inputValue : ''
					// 		};
					// 		that.inputArr.push(obj);
					// 	}
					// }
					// console.log(that.inputArr);
				})
			}, 100);
		},
		methods: {
			radioChange(val){
				$("#myInput").focus();
			},
			// 倒计时
			TimeDown(value) {
				var that = this;
				//倒计时的总秒数
				var totalSeconds = parseInt(value / 1000);
				//取模（余数）
				var modulo = totalSeconds % (60 * 60 * 24);
				//小时数
				var hours = Math.floor(modulo / (60 * 60));
				modulo = modulo % (60 * 60);
				//分钟
				var minutes = Math.floor(modulo / 60);
				//秒
				var seconds = modulo % 60;
				hours = hours.toString().length == 1 ? '0' + hours : hours;
				minutes = minutes.toString().length == 1 ? '0' + minutes : minutes;
				seconds = seconds.toString().length == 1 ? '0' + seconds : seconds;
				//输出到页面
				that.minutesLimit = hours + ":" + minutes + ":" + seconds;
				//延迟一秒执行自己
				if (hours == "00" && minutes == "00" && parseInt(seconds) - 1 < 0) {
					that.minutesLimit = '00:00:00';
					// that.$alert('答题时间已超时！', '提示', {
					// 	confirmButtonText: '确定',
					// 	type: 'warning',
					// 	callback: action => {}
					// });
				} else {
					that.timeOut = setTimeout(function() {
						that.TimeDown(value - 1000);
					}, 1000)
				}
			},
			//用户输入显示
			show(index) {
				var that = this;
				var myInputPass = that.inputValue; //用户输入显示
				var arr = myInputPass.split(''); //输入拆分
				$(".formStyle:eq(" + index + ") input.inputStyle").each(function(i, j) {
					j.value = arr[i] ? arr[i] : '';
				});
			},
			// 首先根据id获取详情
			getDetail(row, userId, completed) {
				var that = this;
				clearTimeout(that.timeOut);
				that.minutesLimit = "";
				// 只有答题情况下可倒计时
				if (completed == 'first') {
					var nowTime = new Date().getTime(); //获取当前时间
					that.minutesLimit = row.minutesLimit; //限制考试时长赋值
					if (row.startTs) {
						that.second = (row.startTs + that.minutesLimit * 60 * 1000) - nowTime; // (开考+限时)-当前时间>0 未超时
						if (that.second <= 0) {
							that.minutesLimit = '00:00:00';
							that.$alert('答题时间已超时！', '提示', {
								confirmButtonText: '确定',
								type: 'warning',
								callback: action => {}
							});
						} else {
							that.TimeDown(that.second);
						}
					} else {
						that.second = that.minutesLimit * 60 * 1000;
						that.TimeDown(that.second);
					}
				}

				that.tipShow = completed == 'first' ? false : true;
				that.examData = [];
				that.nowData = [];
				that.inputValue = "";
				that.answerRadioValue = '';
				that.answerCheckValue = [];
				that.categoryId = row.categoryId;
				that.userId = userId;
				if (completed == 'first') {
					that.getQuiz();
				} else {
					that.getResult();
				}
			},
			// 获取试题
			getQuiz() {
				var that = this;
				that.$axios.post("/quiz/load/" + that.categoryId, {}, {
						headers: {
							'exam': 'true'
						}
					})
					.then(res => {
						that.examData = JSON.parse(JSON.stringify(res.data.quizList));
						that.nowIndex = 0;
						that.nowData = that.examData[that.nowIndex];
						that.answersArr = [];
						that.inputArr = [];
						that.watchContent();
					})
					.catch(err => {
						that.$notify({
							title: '失败',
							message: err,
							type: 'error',
							duration: 2000
						});
					})
			},
			// 获取答题详情
			getResult() {
				var that = this;
				var url = "";
				var headers = {};
				if (that.$route.name == "Exam") {
					url = "/category/assign/result/" + that.categoryId + "/" + that.userId;
				} else {
					url = "/quiz/view/" + that.categoryId;
					headers = {
						'exam': 'true'
					};
				}
				that.$axios({
						method: "get",
						url: url,
						headers: headers
					})
					.then(res => {
						that.examData = JSON.parse(JSON.stringify(res.data));
						that.nowIndex = 0;
						that.handleAnswer();
						that.watchContent();
					})
					.catch(err => {
						console.log(err)
						that.$notify({
							title: '失败',
							message: err,
							type: 'error',
							duration: 2000
						});
					})
			},
			// 详情答案的处理
			handleAnswer() {
				var that = this;
				that.correct = ""; //正确答案
				that.nowData = that.examData[that.nowIndex];
				if (that.nowData.type == 1) {
					that.answerRadioValue = that.nowData.result; //单选已选答案
					that.correct = that.nowData.answer;
					// 若此题为false,代表回答错误
					setTimeout(function() {
						$('.el-radio').removeClass('correctDisabled');
						if (!that.nowData.correct) {
							$('.el-radio.is-checked').addClass('correctDisabled'); //当前选中的错误答案标红
							//正确答案未做对标红
							// $('.el-radio').each(function(){
							// 	if($(this).find('.el-radio__original').val()==that.correct){
							// 		$(this).addClass('correctDisabled');
							// 	}
							// })
						}
					}, 10)
				} else if (that.nowData.type == 2) {
					that.answerCheckValue = that.nowData.result.split(""); //多选选已选答案
					that.correct = that.nowData.answer;
					// 若此题为false,代表回答错误
					setTimeout(function() {
						$('.el-checkbox').removeClass('correctDisabled');
						if (!that.nowData.correct) {
							$('.el-checkbox.is-checked').addClass('correctDisabled'); //当前选中的错误答案标红
						}
					}, 10)
				} else {
					// 填空情况下 新颜路|达索零零
					var strArr = that.nowData.result.split("|");
					that.inputArr = JSON.parse(JSON.stringify(strArr)); //多个（）回答问题的数组
					// 填空题正确答案拼接
					var key = 0;
					for (key in that.nowData) {
						if (key.indexOf('option') > -1) {
						  var value = key.substring(key.length - 1, key.length);
						  if (value == 'A') {
							that.correct = that.nowData[key]
						  } else if(that.nowData[key]){
							that.correct += ',' + that.nowData[key]
						  }
						}
					}
					console.log('填空正确答案：'+that.correct);
				}
			},
			// 上一题
			preClick() {
				var that = this;
				// 未答状态
				if (!that.tipShow) {
					// 判断当前是否已经填写
					var myobj = {};
					if ((that.nowData.type == 1 && !that.answerRadioValue) || (that.nowData.type == 2 && that
							.answerCheckValue
							.length < 1) || (that.nowData.type == 3 && that.COMMON.findElem(that.inputArr,'value')<0)) {} else {
						for (var i = 0; i < that.answersArr.length; i++) {
							// 若本来有值则删除
							if ((that.answersArr[i].index === that.nowIndex) && (that.nowData.type == that.answersArr[i]
									.type)) {
								that.answersArr.splice(i, 1);
							}
							// break;
						}
						// 找不到对应的值则push
						if (that.nowData.type == 1) {
							myobj.answer = that.answerRadioValue;
						} else if (that.nowData.type == 2) {
							myobj.answer = JSON.parse(JSON.stringify(that.answerCheckValue));
						} else {
							// 求差集
							var arr1=[];
							that.inputArr.forEach((unit,unitIndex)=>{
								arr1.push(unit.targetIndex)
							})
							var arr2=JSON.parse(JSON.stringify(that.inputLengthArr));
							var result = arr2.filter(function (item) {
								return arr1.indexOf(item) === -1
							})
							result.forEach(item=>{
								var obj = {
									index: that.nowIndex,
									targetIndex: item,
									value: ''
								};
								that.inputArr.push(obj);
							})
							// 填空时获取当前值
							myobj.answer = JSON.parse(JSON.stringify(that.inputArr));
						}
						myobj.type = that.nowData.type;
						myobj.index = that.nowIndex;
						that.answersArr.push(myobj)
					}
				}
				if (that.nowIndex > 0) {
					that.nowIndex--;
				}
				that.nowData = that.examData[that.nowIndex];
				that.inputValue = "";
				that.watchContent();
				// 已答
				if (that.tipShow) {
					that.handleAnswer();
				}
			},
			// 下一题
			nextClick() {
				var that = this;
				if (!that.tipShow) {
					// 判断当前是否已经填写
					var myobj = {};
					if ((that.nowData.type == 1 && !that.answerRadioValue) || (that.nowData.type == 2 && that
							.answerCheckValue
							.length < 1) || (that.nowData.type == 3 && that.COMMON.findElem(that.inputArr,'value')<0)) {
						that.$notify({
							title: '提示',
							message: '请选择/填写答案!',
							type: 'warning',
							duration: 2000
						});
						return
					} else {
						for (var i = 0; i < that.answersArr.length; i++) {
							// 若本来有值则删除
							if ((that.answersArr[i].index === that.nowIndex) && (that.nowData.type == that.answersArr[i]
									.type)) {
								that.answersArr.splice(i, 1);
							}
						}
						// 找不到对应的值则push
						if (that.nowData.type == 1) {
							myobj.answer = that.answerRadioValue;
						} else if (that.nowData.type == 2) {
							myobj.answer = JSON.parse(JSON.stringify(that.answerCheckValue));
						} else {
							// 求差集
							var arr1=[];
							that.inputArr.forEach((unit,unitIndex)=>{
								arr1.push(unit.targetIndex)
							})
							var arr2=JSON.parse(JSON.stringify(that.inputLengthArr));
							var result = arr2.filter(function (item) {
								return arr1.indexOf(item) === -1
							})
							result.forEach(item=>{
								var obj = {
									index: that.nowIndex,
									targetIndex: item,
									value: ''
								};
								that.inputArr.push(obj);
							})
							// push到answer中
							myobj.answer = JSON.parse(JSON.stringify(that.inputArr));
						}
						myobj.type = that.nowData.type;
						myobj.index = that.nowIndex;
						that.answersArr.push(myobj)
					}
				}
				if (that.nowIndex < that.examData.length - 1) {
					that.nowIndex++;
				}
				that.nowData = that.examData[that.nowIndex];
				that.inputValue = "";
				that.answerRadioValue = '';
				that.answerCheckValue = [];
				that.inputArr = [];
				that.watchContent();
				// 已答
				if (that.tipShow) {
					that.handleAnswer();
				}
			},
			// 填空时检测content确定输入框
			watchContent() {
				var that = this;
				// 单选多选整理选项用于列表渲染
				if (that.nowData.type == 1 || that.nowData.type == 2) {
				  var arr = [];
				  var key = 0;
				  for (key in that.nowData) {
					var obj = {};
					if (key.indexOf('option') > -1) {
					  var value = key.substring(key.length - 1, key.length);
					  obj = {
						value: value,
						label: that.nowData[key],
					  }
					  arr.push(obj);
					}
				  }
				  that.list=JSON.parse(JSON.stringify(arr));
				}
				// tipShow为false时未答
				if (!that.tipShow) {
					// 首先已经选中有答案情况下重新赋值给表单
					that.answersArr.forEach(item => {
						if ((item.index === that.nowIndex) && (that.nowData.type == item.type)) {
							if (that.nowData.type == 1) {
								that.answerRadioValue = item.answer
							} else if (that.nowData.type == 2) {
								that.answerCheckValue = JSON.parse(JSON.stringify(item.answer));
							} else {
								that.inputArr = JSON.parse(JSON.stringify(item.answer)); //多个（）回答问题的数组
							}
						}
					})
				}
				//type为填空
				if (that.nowData.type == 3) {
					that.inputLengthArr=[];
					const reg = /\(\)/g;
					var content = that.nowData.content;
					// 找到()
					var regArr = that.nowData.content.match(reg) ? that.nowData.content.match(reg).length : 0;
					var input = "";
					var inputContent = '';
					for (var i = 1; i <= regArr; i++) {
						//每个()对应答案为几个字
						input = "";
						inputContent = "";
						var fillCountStr = "that.nowData.fill" + i + "Count";
						var fillCount = eval(fillCountStr);
						// 需替代的输入框
						input =
							"&nbsp;<input class='inputStyle' maxlength='1' readonly='readonly'></input>&nbsp;";
						// // 显示几个空格框
						for (var j = 0; j < fillCount; j++) {
							inputContent += input;
						}
						// 判断是未答还是详情
						var focusInput = "";
						if (that.tipShow) {
							focusInput =
								'<input autocomplete="off" disabled class="hiddenInput myhiddenInput" maxlength="' +
								fillCount + '"></input>';
						} else {
							focusInput = '<input autocomplete="off" class="hiddenInput myhiddenInput" maxlength="' +
								fillCount + '"></input>';
						}

						// 替换第几个()
						content = content.replace(/\(\)/, "<form class='formStyle' data-index='" + i + "'>" +
							inputContent + focusInput +
							"</form>");
					}
					setTimeout(function() {
						$('.preContent').html(content);
						that.inputLength=$(".myhiddenInput").length;
						for(var i=0;i<that.inputLength;i++){
							that.inputLengthArr.push(i)
						}
						// 未答情况
						if (!that.tipShow) {
							for (var i = 0; i < that.inputArr.length; i++) {
								that.inputValue = that.inputArr[i].value;
								that.show(that.inputArr[i].targetIndex);
							}
						}
						// 已答情况
						else {
							var strs= new Array(); //定义一数组
							strs=that.correct.split(","); //字符分割
							let indexCatch="";
							$('.formStyle').removeClass('correctDisabled');
							for (var i = 0; i < that.inputArr.length; i++) {
								that.inputValue = that.inputArr[i];
								that.show(i);
								// 若此题为false,代表回答错误
								if (!that.nowData.correct) {
									if (that.nowData.fillReverse) {
										// 可颠倒答案顺序情况,只要匹配则正确
										if((strs.indexOf(that.inputArr[i])>0) && indexCatch!==strs.indexOf(that.inputArr[i])){
											indexCatch=strs.indexOf(that.inputArr[i]);
										}
										else{
											$(".formStyle:eq(" + i + ")").addClass("correctDisabled");
										}
									} else {
										// 不可颠倒顺序的情况
										var test = '';
										switch (i) {
											case 0:
												test = 'optionA'
												break;
											case 1:
												test = 'optionB'
												break;
											case 2:
												test = 'optionC'
												break;
											case 3:
												test = 'optionD'
												break;
											case 4:
												test = 'optionE'
												break;
											case 5:
												test = 'optionF'
												break;
										}
										if (that.nowData[`${test}`] != that.inputArr[i]) {
											$(".formStyle:eq(" + i + ")").addClass("correctDisabled");
										}
									}
								}
							}
						}
					}, 10)
				}
			},
			// 提交答案
			submitClick() {
				var that = this;
				// 判断当前是否已经填写
				var myobj = {};
				if ((that.nowData.type == 1 && !that.answerRadioValue) || (that.nowData.type == 2 && that.answerCheckValue
						.length < 1) || (that.nowData.type == 3 && that.COMMON.findElem(that.inputArr,'value')<0)) {
					that.$notify({
						title: '提示',
						message: '请选择/填写答案!',
						type: 'warning',
						duration: 2000
					});
					return
				} else {
					for (var i = 0; i < that.answersArr.length; i++) {
						// 若本来有值则删除
						if ((that.answersArr[i].index === that.nowIndex) && (that.nowData.type == that.answersArr[i]
								.type)) {
							that.answersArr.splice(i, 1);
						}
						// break;
					}
					// 找不到对应的值则push
					if (that.nowData.type == 1) {
						myobj.answer = that.answerRadioValue;
					} else if (that.nowData.type == 2) {
						myobj.answer = JSON.parse(JSON.stringify(that.answerCheckValue));
					} else {
						// 求差集
						var arr1=[];
						that.inputArr.forEach((unit,unitIndex)=>{
							arr1.push(unit.targetIndex)
						})
						var arr2=JSON.parse(JSON.stringify(that.inputLengthArr));
						//arr2中有 arr1中没有的数组打印
						var result = arr2.filter(function (item) {
							return arr1.indexOf(item) === -1
						})
						// 差集情况push
						result.forEach(item=>{
							var obj = {
								index: that.nowIndex,
								targetIndex: item,
								value: ''
							};
							that.inputArr.push(obj);
						})
						myobj.answer = JSON.parse(JSON.stringify(that.inputArr));
					}
					myobj.type = that.nowData.type;
					myobj.index = that.nowIndex;
					that.answersArr.push(myobj)
				}
				// 处理请求参数
				that.answers = [];
				that.answersArr.forEach((item, index) => {
					if (item.type == 1) {
						that.answers.push(item.answer);
					} else if (item.type == 2) {
						var selectValue = ''; //多选时
						// A-F进行排序
						item.answer.sort().forEach(unit => {
							selectValue = selectValue + unit;
						})
						that.answers.push(selectValue);
					} else {
						var inputValue = ''; //填空时
						var arr = item.answer.sort(that.COMMON.compare('targetIndex'));
						arr.forEach((unit, unitIndex) => {
							if (unit.targetIndex === 0) {
								inputValue = unit.value;
							} else {
								inputValue = inputValue + '|' + unit.value;
							}
						})
						that.answers.push(inputValue);
					}
				})
				that.$axios.post("/quiz/submit/" + that.categoryId, {
						answers: that.answers
					}, {
						headers: {
							'exam': 'true'
						}
					})
					.then(res => {
						that.detail = JSON.parse(JSON.stringify(res.data.detail));
						that.score = res.data.score;
						that.scoreExtra= res.data.scoreExtra?res.data.scoreExtra:0;
						that.tipShow = true;
						that.dialogVisible = true;
					})
					.catch(err => {
						that.$notify({
							title: '失败',
							message: err,
							type: 'error',
							duration: 2000
						});
					})
			},
			// 点击返回,所有弹框都关闭
			goback() {
				this.dialogVisible = false;
				this.$emit('formBtn', 'close'); //传考试端
				this.$emit('subformBtn', 'close'); //传管理端
			},
			// 查看解析详情,再次请求获取数据
			seeDetail() {
				this.dialogVisible = false;
				this.getResult(this.categoryId, this.userId, 'second');
			}

		}
	}
</script>

<style lang='scss' scoped>
	.myForm {
		margin: 0 auto;
		/* pc端 */
		.pccontent {
			margin: 0 auto;
			color: #000000;

			.pcTop {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				justify-content: space-between;
				margin: 15px;
				margin: 0 auto;
				font-size: 16px;
				width: 65%;
				min-width: 400px;
				line-height: 50px;

				strong {
					font-size: 18px;
				}
			}

			.pcMain {
				font-size: 16px;
				margin: 0 auto;
				margin-top: 2%;
				text-align: left;
				width: 60%;
				min-width: 400px;

				.titleCont {
					line-height: 30px;
					white-space: pre-wrap !important;
				}

				/* 填空时 */
				.preContent {
					line-height: 30px;
					white-space: pre-wrap !important;
				}

				.selectOptions {
					ol {

						/* 单选,多选 */
						.el-radio-group,
						.el-checkbox-group {
							width: 100%;

							label {
								display: flex;
								align-items: flex-start;
							}

							.el-radio,
							.el-checkbox {
								color: #000000;
								margin: 3% 0;
							}
						}

						list-style-type:upper-latin;

						li {
							margin-left: 15px;
							white-space: pre-wrap;
						}
					}
				}

				/* 出题信息 */
				.correct span {
					font-size: 14px;
					background: #47B865;
					color: #FFFFFF;
				}

				.myInfor {
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 14px;
					margin-top: 2%;

					.myAuthor {
						color: #D7A24A;
					}

					.myTips {
						color: #47B865;

						p {
							cursor: pointer;
						}
					}
				}

			}
		}
	}
</style>
